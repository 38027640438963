import { useContext, useEffect } from "react"
import { useParams } from "react-router-dom"
import { Box, Button, Chip, Fab, Grid, IconButton, Link, Tooltip, Typography } from "@mui/material"
import AppBar from "../components/AppBar"
import { GamesInfo } from "../helpers/GameseInfo"
import NotFound from "./NotFound"
import Context from "../contexts/context"
import ShopIcon from "@mui/icons-material/Shop"
import AppleIcon from "@mui/icons-material/Apple"
import AndroidIcon from "@mui/icons-material/Android"
import ComputerIcon from '@mui/icons-material/Computer';
import PublicIcon from '@mui/icons-material/Public';
import { useTheme } from "@emotion/react"
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import 'photoswipe/style.css';
import Footer from "../components/Footer"

export default function Game(props) {

	let { gameId } = useParams();

	const context = useContext(Context);

	const { appBarHeight } = context

	const theme = useTheme()

	const handleScrollTop = () => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth'
		})
	}

	useEffect(() => {
		let lightbox = new PhotoSwipeLightbox({
			gallery: '#screenshotGallery',
			children: 'a',
			pswpModule: () => import('photoswipe'),
		});
		lightbox.init();

		return () => {
			lightbox.destroy();
			lightbox = null;
		};
	}, []);

	return (
		<>
			{
				gameId && gameId in GamesInfo ?
					<>
						<AppBar></AppBar>
						<Box pt={appBarHeight + "px"} pb={4} sx={{
							position: "relative",
							minHeight: "100svh",
							backgroundImage: "repeating-linear-gradient(-45deg, rgba(255,255,255, 0.25), rgba(255,255,255, 0.25) 1px, transparent 1px, transparent 6px);",
							backgroundSize: "4px 4px;",
							"&::after": {
								content: "''",
								position: "absolute",
								background: "radial-gradient(circle, rgba(0,0,0, 0) 40%, rgba(0,0,0, 0.2) 50%, rgba(0,0,0, 1) 100%)",
								top: 0,
								right: 0,
								bottom: 0,
								left: 0,
								zIndex: 0,
							},
							"& .MuiGrid-item": {
								position: "relative",
								zIndex: 100,
								"img": {
									maxWidth: "100%",
									maxHeight: `calc(100svh - ${appBarHeight}px)`,
									objectFit: "cover",
									objectPosition: "center top",
									filter: `drop-shadow(0 0 1rem rgba(0,0,0))`,
									"&.cover": {
										width: "100%",
									},
								},
								"a.vertical": {
									maxWidth: "calc(calc(100% - 16px) / 2)",
									[theme.breakpoints.up("md")]: {
										maxWidth: "calc(calc(100% - 32px) / 3)",
									}
								}
							}
						}}>

							<Grid container pb={4} sx={{
								position: "relative"
							}}>
								{/* COVER */}
								<Grid item xs={12} md={7} lg={8} sx={{
									maxHeight: `calc(100svh - ${appBarHeight}px)`,
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									order: 1,
									[theme.breakpoints.down("sm")]: {
										".mobile": {
											display: "block",
										},
										".desktop": {
											display: "none",
										}
									},
									[theme.breakpoints.up("sm")]: {
										".mobile": {
											display: "none",
										},
										".desktop": {
											display: "block",
										}
									}
								}}>
									<img src={GamesInfo[gameId].cover.mobile} alt="" className="mobile cover" />
									<img src={GamesInfo[gameId].cover.desktop} alt="" className="desktop cover" />
								</Grid>

								{/* TITLE AND DESCRIPTION */}
								<Grid item xs={12} md={5} lg={4} sx={{
									position: "sticky !important",
									top: appBarHeight + "px",
									filter: `drop-shadow(0 0 2rem rgba(0,0,0, 1))`,
									zIndex: "900 !important",
									order: 2,
									py:2,
									px: 2,
									[theme.breakpoints.down("md")]: {
										top: "auto",
										bottom: "-15%",
										order: 5,
										paddingTop: "30px",
										backdropFilter: "blur(8px)",
										// backgroundImage: "linear-gradient(180deg, rgba(0,0,0, 0) 10%, rgba(0,0,0,1) 20%)"
									}
								}}>
									<Typography variant="h1" mb={1} display="inline-flex" justifyContent="space-between" alignItems="flex-start" width="100%" sx={{
										gap: 1,
										[theme.breakpoints.up("md")]: { flexWrap: "wrap", flexDirection: "column", gap: 2}}}><span>{GamesInfo[gameId].title}</span>

											{GamesInfo[gameId].game_urls.map(el => {
												return <Tooltip label={el.site} key={"url_" + gameId + el.site}><Button variant="contained" color="primary" href={el.url} target="_blank" sx={{ flexShrink: 0, display: "inline-flex", mt: "auto", [theme.breakpoints.down("md")]: {ml:"auto"}}}>{el.label}</Button></Tooltip>
											})}

									</Typography>
									<Typography variant="body2" mt={1}>Publication date: {GamesInfo[gameId].publication_date}</Typography>
									<Box mt={1} sx={{
										width: "100%",
										display: "flex",
										flexWrap: "wrap",
										gap: 1,
									}}>
									<Typography variant="body2">Game genres:</Typography> {GamesInfo[gameId].genres.map((el,i) => {
										return(
											<Chip variant="outlined" key={"genr_"+i} label={el}></Chip>
										)
									})}
									</Box>
									<Box sx={{
										display: "flex",
										alignItems: "center",
										mt: 2
								}}>
										<Typography variant="body1">Platforms: </Typography>
									{
										Object.keys(GamesInfo[gameId].platforms).length > 0 ?
										<Box sx={{
											display: "inline-flex",
											alignItems: "baseline",
											fontSize: "inherit",
											ml: 2,
										}}>
										{Object.keys(GamesInfo[gameId].platforms).map(id => {
											return (
												<Tooltip key={{id}} title={id}>
													<Button key={"link_" + gameId + id}
													variant="outlined"
													href={GamesInfo[gameId].platforms[id]}
													target="_blank"
													display={"inline-flex"}
													aligncontent="flex-end"
													endIcon={
														id === "android" ?
															<AndroidIcon fontSize="inherit" /> :
															id === "apple" ?
																<AppleIcon fontSize="inherit" /> :
																id === "browser" ?
																	<PublicIcon fontSize="inherit" /> :
																	id === "steam" ?
																		<ComputerIcon fontSize="inherit" /> :
																		""
													}>
														{id}

													</Button>
												</Tooltip>
											)
										})}
									</Box>
										:
										" Not yet"
									}
								</Box>
								</Grid>

								{/* VIDEO IF EXISTS */}
								{
									GamesInfo[gameId].video && GamesInfo[gameId].video !== "" ?
										<Grid item xs={12} md={7} lg={8} mt={2} sx={{
											order: 3,
										}}>
											<Box sx={{
												paddingTop: "56.25%",
												width: "100%",
												"iframe": {
													width: "100%",
													height: "100%",
													position: "absolute",
													top: 0,
													left: 0,
													right: 0,
													bottom: 0,
													border: 0,
												}
											}}>

												<iframe src={GamesInfo[gameId].video} title={GamesInfo[gameId].title} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen loading="lazy"></iframe>
											</Box>
										</Grid>
										:
										""

								}
								{/* IMAGES */}
								<Grid item xs={12} md={7} lg={8} id="screenshotGallery" sx={{
									display: "flex",
									flexWrap: "wrap",
									gap: 2,
									alignItems: "center",
									justifyContent: "center",
									py: 2,
									order: 4,
								}}>
									{
										GamesInfo[gameId].images.map((image, index) => {
											return (
												<a
													href={image.src}
													data-pswp-width={image.width}
													data-pswp-height={image.height}
													key={gameId + "screenshot" + index}
													target="_blank"
													rel="noreferrer"
													className={image.type}
												>
													<img loading="lazy" src={image.src} alt={image.alt} />
												</a>
											)
										})
									}
								</Grid>
							</Grid>
							<Fab color="primary" variant="extended" onClick={() => { handleScrollTop() }} sx={{
								position: "absolute",
								zIndex: 900,
								bottom: "16px",
								right: "16px",
								filter: `drop-shadow(0 0 0.15rem ${theme.palette.primary.main})`,
							}}>
								Back to Top<ArrowCircleUpIcon />
							</Fab>
						</Box>
					</>
					:
					<NotFound></NotFound>
			}
			<Footer></Footer>
		</>
	)
}
