import { Box, Divider, Link, Typography, useTheme } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";

export default function TeamProducts(props) {

	const theme = useTheme();

	return (
		<Grid container py={"60px"} spacing={1} sx={{
			width: "100%",
			position:"relative",
			"& span": {
				color: theme.palette.primary.main,
			},
			"& li span, & span.big": {
				fontSize: theme.typography.h4.fontSize,
				textTransform: "uppercase",

			},
			"li":{
				my: 1
			},
			"& .MuiGrid2-root": {
				position: "relative",
				zIndex: 10,
			},
			"&::before": {
				content: "''",
				background: "linear-gradient(0deg, rgba(0,0,0, 1) 0%, rgba(0,0,0, 0) 25%,  rgba(0,0,0, 0) 75%, rgba(0,0,0, 1) 100%)",
				zIndex:0,
				position: "absolute",
				top: 0,
				left: 0,
				right: 0,
				bottom: 0,
			},
			img: {
				maxWidth: "90%",
				objectFit: "contain"
			},
			video: {
				maxWidth: "80%",
				mb: "40px",
				mx: "auto",
			},
			[theme.breakpoints.down("md")]: {
				ul:{
					pl: "20px",
				}
			}
		}}>
			<Grid xs={10} xsOffset={1} lg={6} lgOffset={3} textAlign="center">
				<img sx={{}} src={process.env.PUBLIC_URL + "/assets/images/brand_games.png"} alt="High Stone Games HSG Brand Games"/>
			</Grid>
			<Grid xs={10} xsOffset={1}>
			<Typography variant="h2">Gaming solutions for brands</Typography>
			<Typography variant="h4">Our products are designed to achieve a variety of goals, including:</Typography>
			<Typography variant="h5">
				<ul>
					<li>
						Increasing <span>brand awareness</span> and <span>engagement</span>
					</li>
					<li>
						<span>Educating</span> and <span>informing</span> consumers
					</li>
					<li>
					<span>Promoting</span> products and services
					</li>
					<li>
						<span>Building relationships</span> with customers
					</li>
				</ul>
			</Typography>
			</Grid>
			<Grid xs={10} xsOffset={1} pt={3}>
				<Typography variant="h3" textAlign={"center"} mb={2}>
					We offer <span>two</span> main <span>types</span> of products:
				</Typography>
			</Grid>

			<Grid xs={10} md={5} xsOffset={1} mb={2}>
				<Box sx={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					width: "100%"
				}}>
				<video autoPlay muted loop>
					<source src={process.env.PUBLIC_URL + "/assets/video/galaxy-neon-gif.mp4"} />
				</video>
				</Box>
				<Typography variant="h5">
				<span className="big">Quick-delivery</span> products are designed for <span>short-term</span> campaigns and events. They are typically <span>simple</span> and <span>easy</span> to create, and they can be <span>produced quickly</span> and <span>affordably</span>. However, they may not have the same long-term impact as more in-depth products.
				</Typography>
			</Grid>
			<Grid xs={10} md={5} xsOffset={1} mdOffset={0} mb={2}>
				<Box sx={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					width: "100%"
				}}>
				<video autoPlay muted loop>
					<source src={process.env.PUBLIC_URL + "/assets/video/hex-aerials-gif.mp4"} />
				</video>
				</Box>
				<Typography variant="h5">
					<span className="big">Deep-dive</span> products are designed for <span>longer-term</span> campaigns and initiatives. They are typically <span>more complex</span> and feature-rich, and they can be used to create a <span>deeper connection</span> with consumers. However, they <span>require more time</span> and <span>resources</span> to develop.
				</Typography>
			</Grid>
			<Grid xs={10} xsOffset={1}>
				<Divider sx={{py: 2, mb: 2}} />
			</Grid>
			<Grid xs={10} md={5} xsOffset={1}>
				<Typography variant="h4">
					<span>Quick-delivery</span> products:
				</Typography>
					<br></br>
				<Typography variant="h5">
					Advantages:
					<ul>
						<li>
							Easy to create and produce
						</li>
						<li>
							Affordable
						</li>
						<li>
							High impact
						</li>
					</ul>
					Disadvantages:
					<ul>
						<li>
							Short-term impact
						</li>
						<li>
							May not be as engaging as deep-dive products
						</li>
					</ul>
				</Typography>
			</Grid>
			<Grid xs={10} md={5} xsOffset={1} mdOffset={0}>
				<Typography variant="h4">
					<span>Deep-dive</span> products:
					</Typography>
					<br></br>
				<Typography variant="h5">
					Advantages:
					<ul>
						<li>
							Long-term impact
						</li>
						<li>
							More engaging
						</li>
						<li>
							Can be used to build relationships with consumers
						</li>
					</ul>
					Disadvantages:
					<ul>
						<li>
							More complex and time-consuming to develop
						</li>
						<li>
							More expensive
						</li>
						</ul>
				</Typography>
			</Grid>
			<Grid xs={10} xsOffset={1}>
				<Divider sx={{py: 2, mb: 2}} />
			</Grid>
			<Grid xs={10} xsOffset={1} mt={2}>
				<Typography variant="h3" mb={2}>
					Which type of product is right for you?
				</Typography>
				<Typography variant="h5">
					The best type of product for you will <span>depend on your specific needs</span> and <span>goals</span>. If you are looking for a product that can generate a <span>quick</span> and <span>easy impact</span>, then a <span>quick-delivery</span> product may be a good option. However, if you are looking for a product that can create a <span>lasting impression</span> and <span>build relationships</span> with consumers, then a <span>deep-dive</span> product may be a better choice.
				</Typography>
				<Typography mt="40px" variant="h4">Contact us to discuss your needs and find the right product for you:   <Link href="mailto:info@highstonegames.com" target="_blank" rel="noreferrer" sx={{color:theme.palette.primary.main,[theme.breakpoints.down("lg")]: {fontSize: "5vw"}}}>info@highstonegames.com</Link></Typography>
			</Grid>
		</Grid>
	)
}
