import { useState, useEffect } from "react"
import { Typography } from "@mui/material"

export default function ChangingTitle() {


    const [visibleSubs, setVisibleSubs] = useState(0)
    const handleWrite = () => {
        switch (visibleSubs) {
            case 0:
                return <Typography className={visibleSubs === 0 ? "cta visible" : "cta"} variant="h3" color="white" align="center" sx={{ fontWeight: 900 }}> WE MAKE <br></br> VIDEOGAMES</Typography>
            case 1:
                return <Typography className={visibleSubs === 1 ? "cta visible" : "cta"} variant="h3" color="white" align="center" sx={{ fontWeight: 900 }}> WE MAKE <br></br> ADVERGAMES</Typography>
            case 2:
                return <Typography className={visibleSubs === 2 ? "cta visible" : "cta"} variant="h3" color="white" align="center" sx={{ fontWeight: 900 }}> WE MAKE <br></br> FUNNY GAMES</Typography>
            case 3:
                return <Typography className={visibleSubs === 3 ? "cta visible" : "cta"} variant="h3" color="white" align="center" sx={{ fontWeight: 900 }}> WE MAKE <br></br> 3D GAMES</Typography>
            case 4:
                return <Typography className={visibleSubs === 4 ? "cta visible" : "cta"} variant="h3" color="white" align="center" sx={{ fontWeight: 900 }}> WE MAKE <br></br> MOBILE GAMES</Typography>
            case 5:
                return <Typography className={visibleSubs === 5 ? "cta visible" : "cta"} variant="h3" color="white" align="center" sx={{ fontWeight: 900 }}> WE MAKE <br></br> 2D GAMES</Typography>
            case 6:
                return <Typography className={visibleSubs === 6 ? "cta visible" : "cta"} variant="h3" color="white" align="center" sx={{ fontWeight: 900 }}> WE MAKE <br></br> NFT GAMES</Typography>
            case 7:
                return <Typography className={visibleSubs === 7 ? "cta visible" : "cta"} variant="h3" color="white" align="center" sx={{ fontWeight: 900 }}> WE MAKE <br></br> WEB GAMES</Typography>
            case 8:
                return <Typography className={visibleSubs === 8 ? "cta visible" : "cta"} variant="h3" color="white" align="center" sx={{ fontWeight: 900 }}> WE MAKE <br></br> PC GAMES</Typography>
            default:
                return <Typography className={visibleSubs === 0 ? "cta visible" : "cta"} variant="h3" color="white" align="center" sx={{ fontWeight: 900 }}> WE MAKE <br></br> VIDEOGAMES</Typography>
        }
    }
    useEffect(() => {
        const handleUpdateVisible = async () => {
            setTimeout(() => {
                if (visibleSubs <= 7) {
                    setVisibleSubs(visibleSubs + 1)
                } else {
                    setVisibleSubs(0)
                }
            }, 1500)
        }

        handleUpdateVisible();
    }, [visibleSubs]);
    return (
        <>
        {handleWrite()}
        </>
    )
}
