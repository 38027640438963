import { BrowserRouter, Route, Routes } from "react-router-dom"
import Home from "../pages/Home"
import Games from "../pages/Games"
import Game from "../pages/Game"
import Team from "../pages/Team"
import NotFound from "../pages/NotFound"
import { routes } from "./Routes"
import ScrollToTop from "../components/ScollToTop";
import OurWorks from "../pages/OurWorks"

function MainRouter() {

  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
			<ScrollToTop />
      <Routes>
        <Route path={routes.home} element={<Home />}>
        {/* <Route path={routes.home} element={<Viewer />}> */}

        </Route>
        <Route path={routes.games} element={<Games />}>
        </Route>
        <Route path={routes.team} element={<Team />}>
        </Route>
				<Route path={routes.works} element={<OurWorks />}>
        </Route>
        <Route path={`${routes.games}:gameId/`} element={<Game />}>

        </Route>
          <Route path='*' element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  )
}

export default MainRouter
