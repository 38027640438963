import { Box, Button, Chip, Link, Typography, useTheme } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { routes } from '../routes/Routes';
import { GamesInfo } from "../helpers/GameseInfo";

export default function TeamWorks(params) {

	const {
		navigate
	} = params;
	const theme = useTheme();

	return (
		<>
			<Grid container sx={{
				background: "linear-gradient(0deg, rgba(0,0,0, 1) 0%, rgba(0,0,0, 0) 25%, rgba(0,0,0, 0) 75%, rgba(0,0,0, 1) 100%)",

				"& span": {
					color: theme.palette.primary.main
				},
				"& .MuiLink-root": {
					cursor: "pointer"
				},
				"& img": {
					maxWidth: "100%",
					objectFit: "contain",
					borderRadius: "20px"
				}
			}}>
				{/* LIST OF PRODUCTS */}
				<Grid xs={10} xsOffset={1} sx={{
					mt: "60px"
				}}>
					<Grid xs={12} mb="40px">
						<Typography variant="h2">
							List of products the studio made:
						</Typography>
					</Grid>

					{/* AERIALS */}

					<Grid xs={12} md={10} mdOffset={1} mt="20px">
						<Grid container spacing={2}>
							<Grid xs={12}>
								<Typography variant="h3">Rhythm Action/Shoot'em up, brand game, arcade, browser game, playable on every device</Typography>
								<Box sx={{
									display: "flex",
									gap: 2,
									flexWrap: "wrap",
									alignItems: "center",
									mt: 2
								}}>
									<Typography variant="h4"><span>{GamesInfo["aerials"].title}:</span></Typography>
									{GamesInfo["aerials"].genres.map((el, i) => {
										return (
											<Chip variant="outlined" key={"genr_" + i} label={el}></Chip>
										)
									})}
								</Box>

							</Grid>
							<Grid xs={12} md={6} lg={3} lgOffset={1}>
								<img src={GamesInfo["aerials"].cover.squared} alt="galaxy neon game cover cover" />
							</Grid>
							<Grid xs={12} md={6} lgOffset={1} sx={{
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								gap: 2,
								flexDirection: "column"
							}}>
								<Typography variant="h6"><span>Aerials</span>, our first brand game, was created for <span>Hex Techno Movement</span> to promote the single of the same name. Players and fans were given the opportunity to listen to the single in an exclusive preview.The game was built around the single and does not only include it as a secondary element, but the entire gameplay rhythm and content are based on the track. The playable characters are the two founders of HEX, Lorenzo Raganzini and Paolo Ferrara.</Typography>
								<Button sx={{
									ml: "auto"
								}} variant="contained" onClick={() => { navigate(routes.games + "aerials/") }}>Discover More</Button>
							</Grid>
						</Grid>
					</Grid>

					{/* GALAXY NEON */}

					<Grid xs={12} md={10} mdOffset={1} mt="20px">
						<Grid container spacing={2}>
							<Grid xs={12}>
								<Typography variant="h3">Action shoot'em up, arcade, browser game, playable on every device</Typography>
								<Box sx={{
									display: "flex",
									gap: 2,
									flexWrap: "wrap",
									alignItems: "center",
									mt: 2
								}}>
									<Typography variant="h4"><span>{GamesInfo["galaxy_neon"].title}:</span></Typography>
									{GamesInfo["galaxy_neon"].genres.map((el, i) => {
										return (
											<Chip variant="outlined" key={"genr_" + i} label={el}></Chip>
										)
									})}
								</Box>

							</Grid>
							<Grid xs={12} md={6} lg={3} lgOffset={1}>
								<img src={GamesInfo["galaxy_neon"].cover.squared} alt="galaxy neon game cover cover" />
							</Grid>
							<Grid xs={12} md={6} lgOffset={1} sx={{
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								gap: 2,
								flexDirection: "column"
							}}>
								<Typography variant="h6">Our first browser game, <span>Galaxy Neon</span>, it's a shoot'em up arcade game with a vertical scrolling perspective. Players control a spaceship that must shoot down alien enemies and collect power-ups to progress through the levels and make an High Score. Born as POW we decided to keep it and make it available to everyone.</Typography>
								<Button sx={{
									ml: "auto"
								}} variant="contained" onClick={() => { navigate(routes.games + "galaxy_neon/") }}>Discover More</Button>
							</Grid>
						</Grid>
					</Grid>

					{/* MONSTER SPLITTER */}

					<Grid xs={12} md={10} mdOffset={1} mt="20px">
						<Grid container spacing={2}>
							<Grid xs={12}>
								<Typography variant="h3">Action shoot'em/survival with farming feature game, native for Mobile</Typography>
								<Box sx={{
									display: "flex",
									gap: 2,
									flexWrap: "wrap",
									alignItems: "center",
									mt: 2
								}}>
									<Typography variant="h4"><span>{GamesInfo["monster_splitter"].title}:</span></Typography>
									{GamesInfo["monster_splitter"].genres.map((el, i) => {
										return (
											<Chip variant="outlined" key={"genr_" + i} label={el}></Chip>
										)
									})}
								</Box>
							</Grid>
							<Grid xs={12} md={6} lg={3} lgOffset={1}>
								<img src={GamesInfo["monster_splitter"].cover.squared} alt="monster splitter game cover cover" />
							</Grid>
							<Grid xs={12} md={6} lgOffset={1} sx={{
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								gap: 3,
								flexDirection: "column"
							}}>
								<Typography variant="h6">Our third game, <span>Monster Splitter</span>, it's an action shoot-'em-up/survival game with a farming feature. Players must build a space laboratory to grow space plants and supplies, while also invading alien planets to harvest space seeds, defending the excavators from enemies to collect seeds to plant.</Typography>
								<Button sx={{
									ml: "auto"
								}} variant="contained" onClick={() => { navigate(routes.games + "monster_splitter/") }}>Discover More</Button>
							</Grid>
						</Grid>
					</Grid>

					{/* VIRULENT ADDICTION */}

					<Grid xs={12} md={10} mdOffset={1} mt="20px">
						<Grid container spacing={2}>
							<Grid xs={12}>
								<Typography variant="h3">Action Multiplayer Game, shooter, native for PC</Typography>
								<Box sx={{
									display: "flex",
									gap: 2,
									flexWrap: "wrap",
									alignItems: "center",
									mt: 2
								}}>
									<Typography variant="h4"><span>{GamesInfo["virulent_addiction"].title}:</span></Typography>
									{GamesInfo["virulent_addiction"].genres.map((el, i) => {
										return (
											<Chip variant="outlined" key={"genr_" + i} label={el}></Chip>
										)
									})}
								</Box>
							</Grid>
							<Grid xs={12} md={6} lg={3} lgOffset={1}>
								<img src={GamesInfo["virulent_addiction"].cover.squared} alt="virulent addiction game cover cover" />
							</Grid>
							<Grid xs={12} md={6} lgOffset={1} sx={{
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								gap: 2,
								flexDirection: "column"
							}}>
								<Typography variant="h6">Our first PC game, <span>Virulent Addiction</span>, is a 2D action shooter multiplayer game set in a microscopic world. Players can choose from a variety of characters and weapons to battle against infinite waves of enemies, collect genomes and survive.</Typography>
								<Button sx={{
									ml: "auto"
								}} variant="contained" onClick={() => { navigate(routes.games + "virulent_addiction/") }}>Discover More</Button>
							</Grid>
						</Grid>
					</Grid>

					{/* BONGVILLE */}

					<Grid xs={12} md={10} mdOffset={1} mt="20px">
						<Grid container spacing={2}>
							<Grid xs={12}>
								<Typography variant="h3">Action single player run'n'gun, side scrolling, native for Mobile</Typography>
								<Box sx={{
									display: "flex",
									gap: 2,
									flexWrap: "wrap",
									alignItems: "center",
									mt: 2
								}}>
									<Typography variant="h4"><span>{GamesInfo["bongville"].title}:</span></Typography>
									{GamesInfo["bongville"].genres.map((el, i) => {
										return (
											<Chip variant="outlined" key={"genr_" + i} label={el}></Chip>
										)
									})}
								</Box>
							</Grid>
							<Grid xs={12} md={6} lg={3} lgOffset={1}>
								<img src={GamesInfo["bongville"].cover.squared} alt="bongville game cover cover" />
							</Grid>
							<Grid xs={12} md={6} lgOffset={1} sx={{
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								gap: 2,
								flexDirection: "column"
							}}>
								<Typography variant="h6">Our first game, <span>Bongville</span>, it's an action single player run'n'gun game set in a side-scrolling world. Players control a character who must shoot their way through waves of enemies to reach the end of the level.</Typography>
								<Button sx={{
									ml: "auto"
								}} variant="contained" onClick={() => { navigate(routes.games + "bongville/") }}>Discover More</Button>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</>
	)
}
