import { Box, Container, Typography, useTheme, Button } from "@mui/material"
import Grid from '@mui/material/Unstable_Grid2';
import { Suspense, useContext, useEffect, useState } from "react"
import Context from "../contexts/context"
import logo from "../resources/logo-highstonegames.png"
import AppBar from "../components/AppBar"
import { ReactComponent as LogoSvg } from "../resources/hsg-logo-bw.svg"
import { maxHeight } from "@mui/system"
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import CardList from "../components/CardList"
import { GamesInfo } from "../helpers/GameseInfo"
import ChangingTitle from "../components/ChangingTitle"
import TitlesList from "../components/TitlesList"
import Footer from "../components/Footer"
import VideogameAssetIcon from '@mui/icons-material/VideogameAsset';
import ContactUs from "../components/ContactUs";
import Viewer from "../components/Viewer";
import { routes } from "../routes/Routes";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Drip } from "../resources/drip-svg.svg";
import { ReactComponent as DripSmall } from "../resources/drip-svg-small.svg";
import HeroIntro from "../components/HeroIntro";

export default function Home(props) {
  const context = useContext(Context)
  const {
    loading,
    appBarHeight,

  } = context

  const theme = useTheme()

	const navigate = useNavigate();

	const handelScrollTo = (id) => {

		window.scrollTo({
			top: document.querySelector(id)?.offsetTop,
			left: 0,
			behavior: 'smooth'
		})

	}

  return (
    <>
      <AppBar></AppBar>
			<Suspense fallback={
				<Box sx={{
					height: "110svh",
					position: "relative",
					background:"linear-gradient(128deg, rgba(255,204,0,1) 0%, rgba(255,153,0,1) 50%, rgba(204,102,0,1) 100%)",
					"& img": {
						position: "absolute",
						left: "50%",
						top: "50%",
						transform: "translate(-50%, -50%)"
					}
				}} >
					<img src={logo} alt="High Stone Games logo" />
				</Box>
			}>
				{/* <HeroIntro /> */}
				<Viewer></Viewer>
			</Suspense>
			<Box sx={{
				display: "flex",
				overflow: "hidden",
				height: "10svh",
				alignItems: "flex-end",
				mt: "-10svh",
				position:" relative",
				zIndex: 100,
				mb: "-2px",
				svg: {
					minWidth: "100%",
					maxHeight: "100%",
					preserveAspectRatio: "none",
					[theme.breakpoints.up("md")]: {
						"&.small": {
							display:"none"
						},
						"&.big": {
							display: "block"
						},
					},
					[theme.breakpoints.down("md")]: {
						"&.small": {
							display: "block"
						},
						"&.big": {
							display:"none"
						},
					}

				}
			}}>
				<Drip className="big" />
				<DripSmall className="small" />
			</Box>
      {/* <Grid container pt={appBarHeight+"px"} pb={3} justifyContent={"center"} sx={{
        minHeight: "100svh",
				boxSizing: "border-box",
        background:"linear-gradient(128deg, rgba(255,204,0,1) 0%, rgba(255,153,0,1) 50%, rgba(204,102,0,1) 100%)",
        position: "relative",
				alignItems: "center",
				justifyContent: "center",
				gap: 2,
				pb:2,
        "&::after": {
          content: "''",
          position: "absolute",
          // background: "radial-gradient(circle,  rgba(0,0,0, 0) 0%, rgba(0,0,0, 1) 100%)",
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          zIndex: 0,
        },
				"&::before": {
          content: "''",
          position: "absolute",
          background: "linear-gradient(180deg, rgba(0,0,0, 0) 90%,  rgba(0,0,0, 0.2) 95%, rgba(0,0,0, 1) 100%)",
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          zIndex: 0,
        },
        "& .MuiGrid-item": {
          position: "relative",
          zIndex: 100,
        }
      }}>
        <Grid item xs={12} sm={10} md={6} lg={5} xl={4}  >
          <Box sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: 3,
						height: "100%",
						flexShrink: 0,
						flexGrow: 2,
            "svg": {
							height: "100%",
              fill: "#ffffff",
							filter: `drop-shadow(0 0 0.15rem #ffffff)`,
							width: "100%",
							maxWidth: "70%",
							[theme.breakpoints.up("sm")]: {
								maxWidth: "35%",
							},
							[theme.breakpoints.up("md")]: {
								maxWidth: "50%",
							},
							[theme.breakpoints.up("lg")]: {
								maxWidth: "60%",
							},
            }
          }}>
            <LogoSvg/>
          </Box>
        </Grid>
        <Grid item xs={12} sm={10} md={6} sx={{ display: "flex", alignItems: "center", justifyContent: "center", overflow: "hidden", }}>
          <Box sx={{
            position: "relative",
            display: "block",
            flexShrink: 0,
            flexGrow: 2,
            ".cta": {
              // position:"absolute",
							filter: `drop-shadow(0 0 0.15rem #ffffff)`,
              top: 0,
              opacity: 0,
              left: 0,
              right: 0,
              transition: "0.3s opacity ease-in",

              "&.visible": {
                opacity: 1,
              }
            }
          }}> */}
            {/* <Typography variant="h1" color="primary" align="center" sx={{  fontWeight: 900 }}> WE MAKE <br></br> <span sx={{opacity: 0}}>GAMES</span> </Typography> */}
            {/* <ChangingTitle />
          </Box>
        </Grid>
        <Grid item xs={12} pt={2} alignSelf="flex-end">
          <Button
            variant="contained"
						onClick={() => {
							handelScrollTo("#gameContainer");
						}}
            sx={{ background: "primary", color: "white", fontSize: "2rem", padding: "16px 16px", borderRadius: "5rem", position: "sticky", bottom: 0, left: "50%", transform: "translate(-50%, -25%)", "&:hover": {
							color:"black", background: "white"
						} }}>
            <VideogameAssetIcon></VideogameAssetIcon>
          </Button>
        </Grid>
      </Grid> */}
			{/* GAMES SECTION */}
      <Grid container py={3} px="20px" sx={{
				boxSizing: "border-box",
        backgroundImage: "repeating-linear-gradient(-45deg, rgba(255,255,255, 0.25), rgba(255,255,255, 0.25) 1px, transparent 1px, transparent 6px);",
        backgroundSize: "4px 4px;",
        position: "relative",
				px: 2,
				minHeight: "100svh",
        "&::before": {
          content: "''",
          position: "absolute",
          background: "linear-gradient(0deg, rgba(0,0,0, 1) 0%, rgba(0,0,0, 0) 50%, rgba(0,0,0, 1) 100%)",
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          zIndex: 0,
        },
        "& .MuiGrid2-root": {
          position: "relative",
          zIndex: 100,
        }
			}}>
				<Grid xs={12} my="60px"  id="gameContainer">
					<Typography variant="h1" sx={{
						textAlign: "center",
						"& span": {
							color: theme.palette.primary.main
						}
					}}> <span>High Stone Games</span> <br></br> is a Game Studio based in Italy.</Typography>
				</Grid>

				<Grid xs={12} md={4} mdOffset={1} xlOffset={1} xl={4} mt="60px" sx={{
					alignItems: "center",
					justifyContent: "center",
					display: "flex",
					"& img": {
						borderRadius: "20px",
						maxWidth: "100%",
						objectFit: "contain",
					}
				}}>
					<img src="/assets/images/highstonegames-card.png" alt="High Stone Games logotype and logogram"></img>
				</Grid>

				<Grid xs={12} md={5} mdOffset={1} lg={4} xl={5} xlOffset={1} sx={{
					display:"flex",
					alignItems: "flexStart",
					flexDirection: "column",
					justifyContent: "center"
				}}>
					<Typography variant="h5" mt="24px" sx={{
						textAlign: "justify",
						mt: "60px",
						[theme.breakpoints.down("sm")]: {
							fontSize: "16px",
							mt: "30px",
							mx:"auto",
						},

						"& span": {
							color: theme.palette.primary.main
						}
					}}>
						The game studio was founded in 2015 as <span>Independent Game Development Team</span>. <br></br>
						We are passionate about <span>creating fun</span>, engaging, and innovative games.<br></br>
						We have a team of <span>experienced developers</span> and <span>artists</span> who are always looking for new and exciting ways to make <span>games more enjoyable</span>. <br></br>
						We are committed to <span>providing our customers</span> with the best possible <span>gaming experience</span>.
					</Typography>
					<Box sx={{
						display: "flex",
						width: "100%",
						justifyContent: "space-around",
						mt: "20px",
						gap: 2,
						[theme.breakpoints.down("lg")]: {
							flexDirection: "column",
						}
					}}>
						<Button size="large" variant="contained" onClick={() =>{navigate(routes.team)}}>Discover the Team</Button>
						<Button size="large" variant="contained" onClick={() =>{navigate(routes.works)}}>Discover our works and products</Button>
					</Box>
				</Grid>
				{/* <Grid xs={12} py={6} sx={{
					textAlign: "center"
				}}>
					<Button variant="contained" size="large" onClick={() => {navigate(routes.team)}}
						sx={{
							color: "white",
							fontSize: "1.15rem",
							padding: "16px 24px",
							borderRadius: "5rem",
						}}
					>
						Learn more about our products and services
					</Button>
				</Grid> */}
				 <Grid my="80px" xs={12}
				 sx={{
					position: "relative",
					".cta": {
						// position:"absolute",
						filter: `drop-shadow(0 0 0.15rem #ffffff)`,
						top: 0,
						opacity: 0,
						left: 0,
						right: 0,
						transition: "0.3s opacity ease-in",
						fontSize: "8.9vw !important",
						"&.visible": {
							opacity: 1,
						}}
        }}>
					<ChangingTitle />
				</Grid>
        <Grid xs={12} sx={{
        }}>
					<TitlesList />
        </Grid>
				{/* <Grid xs={12} mb="60px">
					<ContactUs/>
				</Grid> */}
      </Grid>
			<Footer></Footer>
    </>
  )
}
