import { Box, Button, Typography, useTheme } from "@mui/material"
import { useNavigate } from "react-router-dom"
import { routes } from "../routes/Routes"
import { ReactComponent as LogoSvg } from "../resources/hsg-logo-bw.svg"
import AppBar from "../components/AppBar"

function NotFound() {
  const navigate = useNavigate()

  const theme = useTheme()

  const handleClick = () => navigate(routes.home)

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: "100svh",
        gap: 2,
      }}
    >
      <AppBar></AppBar>
      <Box sx={{
        display: "flex",
        flexGrow: 0,
        flexShrink: 0,
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        "svg": {
          fill: theme.palette.primary.main,
            maxWidth: "20%",
          }
      }}>
        <LogoSvg/>
      </Box>
      <Typography
        component="div"
        variant="h4"
      >
        Page Not Found
      </Typography>
      <Button
        variant='contained'
        onClick={handleClick}
        sx={{ marginTop: 4 }}
      >
        Back to Home
      </Button>
    </Box>

  )
}

export default NotFound
