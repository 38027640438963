import { Box, Divider, Typography, useTheme } from "@mui/material"
import { useNavigate, Link } from "react-router-dom";
import { routes } from "../routes/Routes"

export default function NavMenu(props) {

	const navigate = useNavigate()

	const theme = useTheme()

	return (
		<Box component="nav" sx={{
			width: "100%",
			display: "flex",
			flexDirection: "column",
			boxSizing: "border-box",
			alignItems: "flex-end",
			"& a": {
				textDecoration: "none",
				cursor: "pointer"
			},
			[theme.breakpoints.down("sm")]: {
				width: window.innerWidth + "px",
			}
		}}>
			<Box sx={{
				width:"100%",
				display: "flex",
				rowGap: "8px",
				columnGap:"20px",
				justifyContent: "space-between",
				flexWrap: "wrap",
				"a": {
					display: "flex",
					color: "white",
				},
			}}>
				<Link to={routes.home}>
					<Typography variant="body1">Home</Typography>
				</Link>
				<Link to={routes.games}>
					<Typography variant="body1">Games</Typography>
				</Link>
				<Link to={routes.team}>
					<Typography variant="body1">About us</Typography>
				</Link>
				<Link to={routes.works}>
					<Typography variant="body1">Our works</Typography>
				</Link>
			</Box>

			<Divider color="#ffffff" sx={{ mt: "8px", mb: "14px", width: "100%" }}></Divider>

			<Box sx={{
				display: "flex",
				gap: "8px",
				flexWrap: "wrap",
				justifyContent: "space-between",
				flexDirection:"row-reverse",
				width: "100%",
				"a": {
					padding: "5px 15px",
					textTransform: "uppercase",
					borderRadius:"50px",
					outline: `1px solid ${theme.palette.primary.main}`,
					color: theme.palette.primary.main,
					display: "flex",
					[theme.breakpoints.down("xl")]:{
						"& p": {
							fontSize: "12px",
						},
					},
				},
			}}>
			<Link to={routes.games + "aerials/"}>
				<Typography variant="body2">Aerials</Typography>
			</Link>
			<Link to={routes.games + "galaxy_neon/"}>
				<Typography variant="body2">Galaxy Neon</Typography>
			</Link>
			<Link to={routes.games + "monster_splitter/"}>
				<Typography variant="body2">Monster Splitter</Typography>
			</Link>
			<Link to={routes.games + "virulent_addiction/"}>
				<Typography variant="body2">Virulent Addiction</Typography>
			</Link>
			<Link to={routes.games + "star_bugs/"}>
				<Typography variant="body2">Star Bugs</Typography>
			</Link>
			<Link to={routes.games + "bongville/"}>
				<Typography variant="body2">Bongville</Typography>
			</Link>


		</Box>
		</Box>
	)
}
