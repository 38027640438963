import React from 'react';
import { createRoot, hydrateRoot  } from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { hydrate, render } from "react-dom";

const root = createRoot(document.getElementById('root'));
// const rootElement = document.getElementById("root");
// if (rootElement.hasChildNodes()) {
//   hydrateRoot(rootElement, <App />);
// } else {
//   hydrateRoot(rootElement, <App />);
// }
root.render(
  // <React.StrictMode>
    <App />
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
