import { Box, Button, Link, Tooltip, Typography, useTheme } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import horizontalBloom from "../resources/hsg-horizontal-bloom.png"
import verticalBloom from "../resources/hsg-vertical-bloom.png"
import NavMenu from "./NavMenu";

export default function Footer(params) {

	const theme = useTheme()

	return (
		<Box sx={{
			background: "black",
			color: "#fff",
			padding: "15px 20px",
		}}>
			<Grid container spacing={4}>
				{/* <Grid xs={12} md={3}  xl={2} sx={{
					"img": {
						maxWidth: "100%",
					},
					[theme.breakpoints.down("md")]: {
						".horizontal": {
							display: "block"
						},
						".vertical": {
							display: "none"
						},
						order: 4
					},
					[theme.breakpoints.up("md")]: {
						".vertical": {
							display: "block"
						},
						".horizontal": {
							display: "none"
						},
						order: 0
					}
				}}>
					<img class="horizontal" src={horizontalBloom} alt="HSG Logo horizontal with blooming effect" />
					<img class="vertical" src={verticalBloom} alt="HSG Logo vertical with blooming effect" />
				</Grid> */}

				<Grid xs={12} md={8} lg={8} xl={7} sx={{
					display:"flex",
					alignItems:"center",
					[theme.breakpoints.down("md")]: {
						justifyContent: "center"
					}
				}}>
					<Box>
						<Typography variant="h3" sx={{"& span": {color: theme.palette.primary.main},[theme.breakpoints.down("md")]: {fontSize: "9vw"}}}>
							Discover your next <span>gaming experience</span><br></br> Get in touch today!
						</Typography>
						<Box sx={{
							display:"flex",
							alignItems:"center",
							// justifyContent: "center",
							gap: "20px",
							mt: "40px",
							[theme.breakpoints.down("md")]: {
								flexDirection: "column",
								alignItems:"flex-end",
							}
						}}>
							<Typography variant="h4" sx={{display: "flex"}}>contact us:</Typography>
							<Button variant="outlined" href="mailto:info@highstonegames.com" target="_blank" rel="noreferrer" sx={{textTransform: "lowercase",[theme.breakpoints.down("md")]: {fontSize: "5vw"}, [theme.breakpoints.up("md")]: {fontSize: "2vw"}}}>info@highstonegames.com</Button>
						</Box>
					</Box>
				</Grid>

				<Grid xs={12} md={4} lg={4} xl={5}sx={{
					display:"flex",
					alignItems:"center",
					justifyContent: "center"
				}}>
					<NavMenu />
				</Grid>
			</Grid>

			<Grid container spacing={2} my={4}>

				<Grid xs={12} md={4} sx={{
					[theme.breakpoints.down("md")]: {
						order: "2",
					}
				}}>
					<Typography sx={{
						textAlign: "center",
						[theme.breakpoints.up("md")]: {
							textAlign: "left"
						}
					}} variant="h6">© 2024 High Stone Games</Typography>
				</Grid>

				<Grid xs={12} md={4} sx={{
					[theme.breakpoints.down("md")]: {
						order: "0",
					}
				}}>
					<Box sx={{
						display: "flex",
						flexWrap: "wrap",
						gap: 1,
						alignItems: "center",
						justifyContent: "center",

					}}>
						<Tooltip title="Facebook">
							<Link href="https://www.facebook.com/HighStoneGames/" target="_blank">
								<FacebookIcon />
							</Link>
						</Tooltip>
						<Tooltip title="Instagram">
							<Link href="https://www.instagram.com/highstonegames/" target="_blank">
								<InstagramIcon />
							</Link>
						</Tooltip>
						<Tooltip title="Twitter">
							<Link href="https://twitter.com/highstonegames" target="_blank">
								<TwitterIcon />
							</Link>
						</Tooltip>
						<Tooltip title="LinkedI">
							<Link href="https://www.linkedin.com/company/high-stone-games/" target="_blank">
								<LinkedInIcon />
							</Link>
						</Tooltip>
					</Box>
				</Grid>

				<Grid xs={12} md={4} sx={{
					[theme.breakpoints.down("md")]: {
						order: "1",
					}
				}}>
					<Typography sx={{
						textAlign: "center",
						[theme.breakpoints.up("md")]: {
							textAlign: "right"
						}
					}} variant="body1">Game <Link fontSize="inherit" href="https://www.iubenda.com/privacy-policy/47538271">Privacy Policy</Link></Typography>
				</Grid>
			</Grid>
		</Box >
	)
}
