import './App.css';
import AppContext from './contexts/AppContext';
import MainRouter from './routes/MainRouter';

function App() {

  return (
    <AppContext>
        <MainRouter />
    </AppContext>
  );
}

export default App;
