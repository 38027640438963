import { Box, Link, Typography, useTheme } from "@mui/material";


export default function ContactUs(props) {

	const theme = useTheme();

	return(
		<>
		<Box>
			<Typography align="center" variant="h2" sx={{"& span": {color: theme.palette.primary.main},[theme.breakpoints.down("lg")]: {fontSize: "9vw"}}}>
				Do you need a <span>videogame?</span>
			</Typography>
			<Typography mt="40px" align="center" variant="h4">contact us:  <Link href="mailto:info@highstonegames.com" target="_blank" rel="noreferrer" sx={{color:theme.palette.primary.main,[theme.breakpoints.down("lg")]: {fontSize: "5vw"}}}>info@highstonegames.com</Link></Typography>
		</Box>
		</>
	)
}
