import { Box, IconButton, Typography, useTheme } from "@mui/material"
import { TeamInfos } from "../helpers/TeamInfo"
import LinkedInIcon from '@mui/icons-material/LinkedIn';

export default function TeamImages(params) {
	const theme = useTheme();

	return(
		<Box sx={{
			px: "24px",
			pb: "80px",
			pt: "80px"
		}}>
		<Typography variant="h2" sx={{
			textAlign: "center",
			mb: "16px"
		}}>TEAM MEMBERS</Typography>
		<Typography variant="h4" sx={{
			textAlign: "center",
			mb: "40px"
		}}>CURRENT FORMATION</Typography>
		<Box sx={{
			display: "flex",
			gap: "20px",
			justifyContent: "space-around",
			flexWrap: "wrap",
			// flexDirection: "column",
			alignItems: "stretch",
			boxSizing: "border-box",
		}}>
			{TeamInfos.team_images.map((el, i) => {
				return(
					<Box
					key={"team_image_"+i}
					sx={{
						boxSizing: "border-box",
						width: "calc(calc(100% - 100px) / 4)",
						display: "flex",
						alignItems: "center",
						padding: "30px 20px 30px 20px",
						[theme.breakpoints.down("lg")]: {
						padding: "20px 10px 20px 10px",
						minWidth: "calc(calc(100% - 60px) / 2)",
					},
					[theme.breakpoints.down("md")]: {
						width: "90%",
					},
					position: "relative",
					"&::after":{
						content: "''",
						background: `linear-gradient(180deg, #ffffff33 0%, #ffffff11 100%)`,
						border: "2px solid white",
						borderRadius: "80px",
						position: "absolute",
						left: 0,
						top: 0,
						right: 0,
						bottom: 0,
						zIndex: -1,
						[theme.breakpoints.down("md")]: {
							borderRadius: "40px",
						}
					},
				}}>
				<Box sx={{
					display: "flex",
					rowGap: "20px",
					justifyContent: "space-between",
					alignItems: "center",
					textAlign: "center",
					flexDirection: "column",
					height: "100%",
					// background: "rgb(209,101,0)",
					// marginTop: "70%",
					filter: `drop-shadow(0 0 0.75rem darken(${theme.palette.primary.main}, 45%))`,
					flexGrow: 4,
					maxWidth: "690px",
					width: "100%",
					img: {
						display: "block",
						borderRadius: "300px",
						width: "450px",
						filter: "drop-shadow(0 0 0.95rem #111111)",
						maxWidth: "80%",
						[theme.breakpoints.down("lg")]: {
							maxWidth: "50%",
						}
					}
				}}>
					{/* <img src={el.src} alt={el.alt} /> */}
					<Box sx={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						flexGrow: 2,
						p:2,
					}}>
					<Typography variant="h3">{el.description.name}</Typography>
					<Typography variant="h5" color="primary">{el.description.role}</Typography>
					<IconButton aria-label="linkedin link" sx={{
						alignSelf: "center",
					}} href={el.description.link} target="_blank">
					  <LinkedInIcon fontSize="large"/>
					</IconButton>
					</Box>
				</Box>
				</Box>
				);
			})
			}
			</Box>

		</Box>
	)
}
