import { Box, Card, Typography, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";


export default function CardList(props) {

    const theme = useTheme()

    const navigate = useNavigate();

    return (
        <Box sx={{
            display: "flex",
            flexDirection: "column",
            flexWrap: "wrap",
            gap: 2,
						p: 2,
						alignItems: "stretch",
						justifyContent: "center",
            [theme.breakpoints.up("sm")]: {
                flexDirection: "row"
            }
        }}>
            {
                Object.keys(props.children).map(child => {
                    let game = props.children[child]
                    return (
                    <Card key={"game_"+child}
												elevation={0}
                        onClick={() => {
                            navigate("/games/"+child+"/")
                        }}
                        sx={{
                        background: "rgba(12,12,12,0.1)",
                        padding: 2,
                        gap: 1,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        borderRadius: "32px",
                        cursor: "pointer",
                        transition: "0.3s all ease-in",
												boxSizing: "border-box",
												backdropFilter: "blur(8px)",
												"& .MuiTypography-root": {
													transition: "0.3s all ease-in",
												},
                        "&:hover": {
                            "img": {
                                transform: "scale(1.01)",
                            },
														"& .MuiTypography-root": {
															filter: `drop-shadow(0 0 0.15rem #ffffff)`,
														}
                        },
                        "img": {
                            transition: "0.3s all ease-in",
                            maxWidth: "100%",
                            aspectRatio: 1,
                            display: "block",
                            borderRadius: "24px"
                        },
												width: "100%",
												[theme.breakpoints.up("sm")]: {
													maxWidth: "calc(calc(100% - 16px) / 2)"
												},
												[theme.breakpoints.up("md")]: {
													maxWidth: "calc(calc(100% - 32px) / 3)"
												},
												[theme.breakpoints.up("lg")]: {
													maxWidth: "calc(calc(100% - 48px) /4)"
												}
                        }}
												>
                      <img
                        src={game.cover.squared}
                        alt={game.title + " game cover"}/>
                        <Typography variant="h3" align="center">
                            {game.title}
                        </Typography>
                    </Card>)
                })
            }
        </Box>
    )
}
