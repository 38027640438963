export const TeamInfos = {
	team_images: [
	  {	src: "/assets/images/team/team-pippo.jpg",
	    alt: "cartoon portrait filippo high stone games team member",
	    description: {
				name: "Filippo Lovat",
	      role: "Lead Game Developer",
	      link: "https://www.linkedin.com/in/filippolovat/"
			}
		},
	  // { src: "/assets/images/team/team-tullio.jpg",
	  //   alt: "cartoon portrait Tullio high stone games team member",
	  //   description: {
		// 		name: "Tullio Gulì",
	  //     role: "Game Developer",
	  //     link: "https://www.linkedin.com/in/tullio-guli"
		// 	}
		// },
	  { src: "/assets/images/team/team-gongus.jpg",
	    alt: "cartoon portrait giorgio high stone games team member",
	    description: {
	      name: "Giorgio Borghetto",
	      role: "PR & Game Developer",
	      link: "https://www.linkedin.com/in/giorgio-borghetto-28ab38102/"
			}
		},
	  { src: "/assets/images/team/team-nico.jpg",
	    alt: "cartoon portrait nico high stone games team member",
	    description: {
				name: "Nicolò Azzolini",
	      role: "Game Designer & Tech Artist",
	      link: "https://www.linkedin.com/in/nico-azzolini"
			}
		},
	  {src: "/assets/images/team/team-giano.jpg",
	    alt: "cartoon portrait giano high stone games team member",
	    description: {
				name: "Francesco Giannuzzi",
	      role: "Game Artist & Web Developer",
	      link: "https://www.linkedin.com/in/francesco-giannuzzi-907159106/"
			}
		}
		]
}
