export const GamesInfo = {
	"aerials": {
		title: "Aerials",
		game_urls: [
			{
				label: "Play it!",
				site: "HighStoneGames.com",
				url: "/game/hex/"
			}
		],
		genres: ["Shoot-em-up", "Rhythm action", "Arcade", "Hardcore", "Action", "2D", "Single player", "Mobile"],
		images: [
		{
			src: "/assets/images/games/hex-aerials-screenshot-1.jpg",
			alt: "Aerials in game menu screenshot",
			type: "vertical"
		},
		{
			src: "/assets/images/games/hex-aerials-screenshot-2.jpg",
			alt: "Aerials tutorial, tips and tricks",
			type: "vertical"
		},
		{
			src: "/assets/images/games/hex-aerials-screenshot-3.jpg",
			alt: "Aerials gameplay screenshot with Luca Ferrara",
			type: "vertical"
		},
		{
			src: "/assets/images/games/hex-aerials-screenshot-4.jpg",
			alt: "Aerials gameplay screenshot word AERIALS",
			type: "vertical"
		},
		{
			src: "/assets/images/games/hex-aerials-screenshot-5.jpg",
			alt: "Aerials gameplay screenshot boss fight with Lorenzo Raganzini",
			type: "vertical"
		},
		{
			src: "/assets/images/games/hex-aerials-screenshot-6.jpg",
			alt: "Aerials gameplay screenshot boss defeat",
			type: "vertical"
		},
		{
			src: "/assets/images/games/hex-aerials-screenshot-7.jpg",
			alt: "Aerials leaderboards",
			type: "vertical"
		},
		],
		cover: {
			mobile: "/assets/images/games/hex-aerials-cover-mobile.png",
			desktop: "/assets/images/games/hex-aerials-cover.jpg",
			squared: "/assets/images/games/hex-aerials-cover-squared.png",
		},
		logo: "/assets/images/games/hex-aerials-logo.png",
		platforms: {
			browser: "/game/hex/",
		}
		,
		publication_date: "19/09/2023",
		video: "https://www.youtube.com/embed/_XK8dxg7I6E",
	},
	"galaxy_neon": {
		title: "Galaxy Neon",
		game_urls: [
			{
				label: "Play it!",
				site: "HighStoneGames.com",
				url: "/game/galaxy_neon/"
			}
		],
		genres: ["Shoot-em-up", "Arcade", "Hypercasual", "Action", "2D", "Single player", "Casual Game", "Mobile"],
		images: [{
			src: "/assets/images/games/galaxy-neon-screenshot-1-desktop.png",
			alt: "Monster Splitter in game splash screen",
			type: "horizontal"
		},
		{
			src: "/assets/images/games/galaxy-neon-screenshot-1.png",
			alt: "Monster Splitter gameplay screenshot",
			type: "vertical"
		},
		{
			src: "/assets/images/games/galaxy-neon-screenshot-2.png",
			alt: "Monster Splitter gameplay screenshot",
			type: "vertical"
		},
		{
			src: "/assets/images/games/galaxy-neon-screenshot-3.png",
			alt: "Monster Splitter gameplay screenshot",
			type: "vertical"
		},
		{
			src: "/assets/images/games/galaxy-neon-screenshot-4.png",
			alt: "Monster Splitter gameplay screenshot",
			type: "horizontal"
		},
		],
		cover: {
			mobile: "/assets/images/games/galaxy-neon-cover-mobile.png",
			desktop: "/assets/images/games/galaxy-neon-cover.png",
			squared: "/assets/images/games/galaxy-neon-cover-squared.png",
		},
		logo: "/assets/images/games/galaxy-neon-logo.png",
		platforms: {
			browser: "/game/galaxy_neon/",
		}
		,
		publication_date: "28/02/2023",
		video: "",
	},
	"monster_splitter": {
		title: "Monster Splitter",
		game_urls: [
			{
				label: "Download it!",
				site: "Play Store",
				url: "https://play.google.com/store/apps/details?id=com.HighStoneGames.MonsterSplitter"
			}
		],
		genres: ["Shoot-em-up", "2D Cartoon", "Action", "Farming", "Single player", "Mobile"],
		images: [
			{
				src: "/assets/images/games/monster-splitter-splash-1080x1920.png",
				alt: "Monster Splitter in game splash screen",
				type: "vertical"
			},
			{
				src: "/assets/images/games/monster-splitter-gameplay-8.png",
				alt: "Monster Splitter gameplay screenshot",
				type: "vertical"
			},
			{
				src: "/assets/images/games/monster-splitter-gameplay-1.png",
				alt: "Monster Splitter gameplay screenshot",
				type: "vertical"
			},
			{
				src: "/assets/images/games/monster-splitter-gameplay-2.png",
				alt: "Monster Splitter gameplay screenshot",
				type: "vertical"
			},
			{
				src: "/assets/images/games/monster-splitter-gameplay-4.png",
				alt: "Monster Splitter gameplay screenshot",
				type: "vertical"
			},
			{
				src: "/assets/images/games/monster-splitter-gameplay-5.png",
				alt: "Monster Splitter gameplay screenshot",
				type: "vertical"
			},
			{
				src: "/assets/images/games/monster-splitter-gameplay-6.png",
				alt: "Monster Splitter gameplay screenshot",
				type: "vertical"
			},
			{
				src: "/assets/images/games/monster-splitter-gameplay-7.png",
				alt: "Monster Splitter gameplay screenshot",
				type: "vertical"
			},
			{
				src: "/assets/images/games/monster-splitter-title.png",
				alt: "Monster Splitter in game splash screen",
				type: "horizontal"
			},
		],
		cover: {
			mobile: "/assets/images/games/monster-splitter-cover-vertical.png",
			desktop: "/assets/images/games/monster-splitter-cover-horizontal.png",
			squared: "/assets/images/games/monster-splitter-tile-1x1.png"
		},
		logo: "/assets/images/games/monster-splitter-logo.png",
		platforms: {
			android: "https://play.google.com/store/apps/details?id=com.HighStoneGames.MonsterSplitter",
		}
		,
		publication_date: "05/09/2022",
		video: "https://www.youtube.com/embed/xkk-d9ol-r8",
	},
	"virulent_addiction": {
		title: "Virulent Addiction",
		game_urls: [
			{
				label: "Get it!",
				site: "Steam",
				url: "https://store.steampowered.com/app/1146330/Virulent_Addiction/"
			}
		],
		genres: ["Action", "Shooter", "2D Cartoon", "Party Game", "Co-op", "Multiplayer", "PC"],
		images: [
			{
				src: "/assets/images/games/virulent-addiction-screen-1.jpg",
				alt: "Virulent Addiction in game splash screen",
				type: "vertical"
			},
			{
				src: "/assets/images/games/virulent-addiction-screen-2.jpg",
				alt: "Virulent Addiction gameplay screenshot",
				type: "vertical"
			},
			{
				src: "/assets/images/games/virulent-addiction-screen-3.jpg",
				alt: "Virulent Addiction gameplay screenshot",
				type: "vertical"
			},
			{
				src: "/assets/images/games/virulent-addiction-screen-4.jpg",
				alt: "Virulent Addiction gameplay screenshot",
				type: "vertical"
			},
			{
				src: "/assets/images/games/virulent-addiction-screen-5.jpg",
				alt: "Virulent Addiction gameplay screenshot",
				type: "vertical"
			},
		],
		cover: {
			mobile: "/assets/images/games/virulent-addiction-banner-vertical.jpg",
			desktop: "/assets/images/games/virulent-addiction-banner-horizontal.jpg",
			squared: "/assets/images/games/virulent-addiction-squared.png"
		},
		logo: "/assets/images/games/virulent-addiction-logo.png",
		platforms: {
			steam: "https://play.google.com/store/apps/details?id=com.HighStoneGames.MonsterSplitter",
		}
		,
		publication_date: "02/05/2020",
		video: "https://www.youtube.com/embed/UxYSpXQA0ug",
	},
	"star_bugs": {
		title: "Star Bugs",
		game_urls: [
			{
				label: "Discover it!",
				site: "StarBugs.org",
				url: "https://starbugs.org"
			}
		],
		infos: {
			title: "Action single player run'n'gun, side scrolling, native for Mobile",
			description:"Our first game, <span>Bongville</span>, is an action single player run'n'gun game set in a side-scrolling world. Players control a character who must shoot their way through waves of enemies to reach the end of the level."
		},
		genres: ["Card game", "Auto battler", "Collecting game", "NFT", "Mobile"],
		images: [
			{
				src: "/assets/images/games/star-bugs-main-menu.png",
				alt: "Star Bugs gameplay screenshot",
				type: "horizontal"
			},
			{
				src: "/assets/images/games/star-bugs-gamplay-vs.png",
				alt: "Star Bugs gameplay screenshot",
				type: "horizontal"
			},
			{
				src: "/assets/images/games/star-bugs-gamplay-2.png",
				alt: "Star Bugs in game splash screen",
				type: "horizontal"
			},
			{
				src: "/assets/images/games/star-bugs-gamplay-hand.png",
				alt: "Star Bugs gameplay screenshot",
				type: "vertical"
			},
			{
				src: "/assets/images/games/star-bugs-levels.png",
				alt: "Star Bugs gameplay screenshot",
				type: "vertical"
			},
			{
				src: "/assets/images/games/star-bugs-deck.png",
				alt: "Star Bugs gameplay screenshot",
				type: "vertical"
			},
			{
				src: "/assets/images/games/star-bugs-gamplay-1.png",
				alt: "Star Bugs gameplay screenshot",
				type: "horizontal"
			},
		],
		cover: {
			mobile: "/assets/images/games/star-bugs-banner-mobile.png",
			desktop: "/assets/images/games/star-bugs-banner-desktop.png",
			squared: "/assets/images/games/star-bugs-banner-squared.png"
		},
		logo: "/assets/images/games/starbugs-banner-logo.png",
		platforms: {

		}
		,
		publication_date: "prototype 2022",
		video: "https://www.youtube.com/embed/8avMasYtLzI",
	},
	"bongville": {
		title: "Bongville",
		game_urls: [
			{
				label: "Play it!",
				site: "HighStoneGames.com",
				url: "/game/bongville/"
			}
		],
		infos: {
			title: "Action single player run'n'gun, side scrolling, native for Mobile",
			description:"Our first game, <span>Bongville</span>, is an action single player run'n'gun game set in a side-scrolling world. Players control a character who must shoot their way through waves of enemies to reach the end of the level."
		},
		genres: ["Run'N'Gun", "Shooting", "Action", "2.5D", "Beat'm Up", "Single Player", "Mobile"],
		images: [
			{
				src: "/assets/images/games/bongville-screenshot-1.png",
				alt: "Bongville in game splash screen",
				type: "vertical"
			},
			{
				src: "/assets/images/games/bongville-screenshot-2.png",
				alt: "Bongville gameplay screenshot",
				type: "vertical"
			},
			{
				src: "/assets/images/games/bongville-screenshot-3.png",
				alt: "Bongville gameplay screenshot",
				type: "vertical"
			},
			{
				src: "/assets/images/games/bongville-screenshot-4.png",
				alt: "Bongville gameplay screenshot",
				type: "vertical"
			},
			{
				src: "/assets/images/games/bongville-screenshot-5.png",
				alt: "Bongville gameplay screenshot",
				type: "vertical"
			},
		],
		cover: {
			mobile: "/assets/images/games/bongville-cover-mobile.png",
			desktop: "/assets/images/games/bongville-cover-desktop.png",
			squared: "/assets/images/games/bongville-cover-squared.png"
		},
		logo: "/assets/images/games/bongville-logo.png",
		platforms: {
			browser: "/game/bongville/",
		}
		,
		publication_date: "3/12/2016",
		video: "https://www.youtube.com/embed/LLzDesidOIU",
		local_url: "/play/monster_splitter",
	}
}
