import AppBar from "../components/AppBar"
import { Box, Grid, Typography, useTheme } from "@mui/material"
import Context from "../contexts/context"
import { useContext } from "react"
import VideogameAssetIcon from '@mui/icons-material/VideogameAsset';
import CardList from "../components/CardList";
import { GamesInfo } from "../helpers/GameseInfo";
import Footer from "../components/Footer";

export default function Games(props) {

	const context = useContext(Context);

	const { appBarHeight } = context

	const theme = useTheme()

	return (
		<Box>
		<Box sx={{
			backgroundColor: theme.palette.background.paper,
			backgroundSize: "contain",
			backgroundPosition: "center center",
			backgroundRepeat: "no-repeat",
			backgroundImage: "url('data:image/svg+xml;utf8,%3Csvg viewBox=%220 0 1000 1000%22 xmlns=%22http:%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cdefs%3E%3ClinearGradient id=%22b%22 gradientTransform=%22rotate(-45 .5 .5)%22%3E%3Cstop offset=%220%25%22 stop-color=%22%23F6C000%22%2F%3E%3Cstop offset=%22100%25%22 stop-color=%22%23f76b1c%22%2F%3E%3C%2FlinearGradient%3E%3CclipPath id=%22a%22%3E%3Cpath fill=%22currentColor%22 d=%22M839 625q-41 125-158.5 179T424 865.5Q285 873 193 759t-45-242q47-128 104.5-237t186-133.5Q567 122 698.5 173T855 362q25 138-16 263Z%22%2F%3E%3C%2FclipPath%3E%3C%2Fdefs%3E%3Cg clip-path=%22url(%23a)%22%3E%3Cpath fill=%22url(%23b)%22 d=%22M839 625q-41 125-158.5 179T424 865.5Q285 873 193 759t-45-242q47-128 104.5-237t186-133.5Q567 122 698.5 173T855 362q25 138-16 263Z%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E')",
			// backgroundImage: `repeating-linear-gradient(-45deg, ${theme.palette.primary.main}, ${theme.palette.primary.main} 1px, transparent 1px, transparent 6px)`,
			// backgroundSize: "4px 4px;",
			minHeight: "100svh",
			position: "relative",
			overflow: "hidden",
			"&::after": {
				content: "''",
				position: "absolute",
				background: "linear-gradient(0deg, rgba(0,0,0, 1) 0%, rgba(0,0,0, 0.2) 50%, rgba(0,0,0, 1) 100%)",
				backdropFilter: "blur(8px)",
				top: 0,
				right: 0,
				bottom: 0,
				left: 0,
				zIndex: 0,
			},
			"& .MuiGrid-item": {
				position: "relative",
				zIndex: 100,
			}
		}}>
			<AppBar></AppBar>
			<Grid container pt={appBarHeight + "px"}>
				<Grid item xs={12} pt={2}>

					<Typography variant="h1" sx={{
						display: "flex",
						alignItems: "center",
						gap: 2,
						flexDirection: "column",
						width: "100%",
						filter: `drop-shadow(0 0 0.15rem ${theme.palette.primary.main})`,
					}}
						align="center"
						color="primary"
					>
						HSG <br></br>
						GAMES
						<VideogameAssetIcon fontSize="inherit" />
					</Typography>
				</Grid>
				<Grid item xs={12} py={4}>
					<CardList children={GamesInfo} />
				</Grid>
			</Grid>
			</Box>
			<Footer />
		</Box>
	)
}
