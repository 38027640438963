import { Canvas, useFrame, useLoader } from '@react-three/fiber'
import { useEffect, useRef } from 'react'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { useGLTF, AccumulativeShadows, RandomizedLight, Environment, Center } from '@react-three/drei';
import { easing } from 'maath'
import { Box, Button } from '@mui/material';
import VideogameAssetIcon from '@mui/icons-material/VideogameAsset';
import { Mesh } from 'three';

export default function Viewer() {

	const handelScrollTo = (id) => {
		console.log(id, document.getElementById(id)?.offsetTop)
		const element = document.getElementById(id);
		if (element) {

			window.scrollTo({
				top: element.offsetTop + element.parentElement.offsetTop - 60,
				left: 0,
				behavior: 'smooth'
			})
		}

	}
  useGLTF.preload(process.env.PUBLIC_URL +'/assets/models/HSG-simple.glb');

  const model = useLoader(GLTFLoader, process.env.PUBLIC_URL +'/assets/models/HSG-simple.glb')
	model.scene.children.forEach((mesh, i) => {
			mesh.castShadow = true;
	})
	model.castShadow = true;
	model.scene.castShadow = true;
  console.log(model);


  const Backdrop = () => {
    const shadows = useRef()
    return (
      <AccumulativeShadows ref={shadows} temporal frames={60} alphaTest={0.75} scale={20} rotation={[0, 0, 0]} position={[0, -0.33, 0]}>
        <RandomizedLight amount={4} radius={8} intensity={0.25} ambient={0.65} position={[0, 5, -5]} />
        <RandomizedLight amount={4} radius={5} intensity={0.55} ambient={0.65} position={[0, 5, 9]} />
      </AccumulativeShadows>
    )
  }

  const CameraRig = ({ children }) => {
    const group = useRef()
    useFrame((state, delta) => {
      easing.damp3(state.camera.position, [0, 0, 2], 0.25, delta)
      easing.dampE(group.current.rotation, [state.pointer.y / 10, -state.pointer.x / 5, 0], 0.25, delta)
    })
    return <group ref={group}>{children}</group>
  }

  return (
    <Box sx={{
			height: "110svh",
			position: "relative",
			background:"linear-gradient(128deg, rgba(255,204,0,1) 0%, rgba(255,153,0,1) 50%, rgba(204,102,0,1) 100%)",
			"& #viewer3D": {
				height: "100%",
				width: "100%"
			}
		}}>
      <Canvas id="viewer3D" shadows camera={{ position: [0, 0, 2.5], fov: 25 }} gl={{ preserveDrawingBuffer: true }} eventSource={document.getElementById('root')} eventPrefix="client" >
        {/* <OrbitControls enablePan={false} /> */}
          <ambientLight intensity={0.5} />
          <Environment files={process.env.PUBLIC_URL + "/assets/hdr/potsdamer_platz_1k.hdr"}/>
          <CameraRig>
          <Backdrop />
          <Center position={[0, 0.05, 0]}>
          	<primitive castShadow object={ model.scene } rotation-x={0} scale={[1.4,1.4,1.4]} />
            {/* <primitive object={ shirt.scene }  scale={[4,4,4]} /> */}

          </Center>
          </CameraRig>

      </Canvas>
			<Button
				variant="contained"
				onClick={() => {
					handelScrollTo("gameContainer");
				}}
				sx={{
					background: "primary",
					color: "white",
					fontSize: "2rem",
					padding: "16px 24px",
					borderRadius: "5rem",
					position: "absolute",
					bottom: "11%",
					left: "50%",
					transform: "translate(-50%, -25%)",
					zIndex: 100, "&:hover": {
					color:"black",
					background: "white"
				} }}>
				<VideogameAssetIcon></VideogameAssetIcon>
			</Button>
    </Box>
  )
}

