import { AppBar as MuiAppBar, Box, IconButton, Link, Typography, useTheme } from "@mui/material"
import { useContext, useState } from "react"
import Context from "../contexts/context"
import bloom from "../resources/hsg-horizontal-bloom.png"
import DrawerMenu from "./DrawerMenu"
import MenuIcon from '@mui/icons-material/Menu';
import { fontSize } from "@mui/system"
import { useNavigate } from "react-router-dom"

export default function AppBar({ children }) {

	const context = useContext(Context)

	const { appBarHeight } = context

	const navigate = useNavigate()

	const theme = useTheme()

	const [openDrawer, setOpenDrawer] = useState(false);

	const toggleDrawer = () => {
		setOpenDrawer(!openDrawer);
	}

	return (
		<>
	<MuiAppBar
		sx={{ height: appBarHeight + "px", background: "rgba(255, 255, 255, 0.1)", backdropFilter: "blur(8px)" }}>
		<Box sx={{
			display: "flex",
			alignItems: "center",
			width: "100%",
			height: "100%",
		}}>
			<Link onClick={() =>{navigate("/")}} aria-label="Homepage site link" sx={{
				cursor: "pointer",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				flexShrink: 0,
				gap: 2,
				height: "100%",
				textDecoration: "none",
				color: "white",
				transition: "0.3s all ease-in",
				"img": {
					maxHeight: "100%",
				},
				"h5, .h5": {
					textTransform: "uppercase",
				},
				filter: `drop-shadow(0 0 0.06rem #ffffff)`,

				"&:hover": {
					filter: `drop-shadow(0 0 0.12rem #ffffff)`,

				}
			}}>
				<img src={process.env.PUBLIC_URL + "/icon-highstonegames.png"} alt="" />
				<Typography variant="h5" sx={{ [theme.breakpoints.down("sm")]: {fontSize: "1rem"} }}>High Stone Games</Typography>
			</Link>
			<IconButton sx={{ml: "auto"}} onClick={toggleDrawer} aria-label="navbar-hamburger-menu">
				<MenuIcon />
			</IconButton>
		</Box>
	</MuiAppBar>
	<DrawerMenu openDrawer={openDrawer} toggleDrawer={toggleDrawer} ></DrawerMenu>
	</>
	)
}
