import Grid from "@mui/material/Unstable_Grid2/Grid2";
import AppBar from "../components/AppBar";
import { useContext } from "react";
import Context from "../contexts/context";
import { useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import TeamProducts from "../components/TeamProducts";
import TeamWorks from "../components/TeamWorks";
import ContactUs from "../components/ContactUs";
import Footer from "../components/Footer";


export default function OurWorks(props) {

	const context = useContext(Context);

	const {
		appBarHeight,
		navigator
	} = context;

	const theme = useTheme();
	const navigate = useNavigate()

	return (
		<>
			<AppBar></AppBar>
			<TeamProducts />

			<TeamWorks
				navigate={navigate} />
			<Grid container py="80px" sx={{background:"#000"}}>
				<Grid xs={12}>
					<ContactUs/>
				</Grid>
			</Grid>
			<Footer></Footer>

		</>
	)
}
