import { Box, Button, Container, Link, Typography, useTheme } from "@mui/material";
import AppBar from "../components/AppBar";
import Context from "../contexts/context"
import { useContext } from "react";
import Footer from "../components/Footer";
import TeamImages from "../components/TeamsImage";
import Grid from '@mui/material/Unstable_Grid2';
import TeamWorks from "../components/TeamWorks";
import { useNavigate } from "react-router-dom";
import ContactUs from "../components/ContactUs";
import TeamProducts from "../components/TeamProducts";
import { routes } from "../routes/Routes";

export default function Team(props) {

	const context = useContext(Context);
	const {
		appBarHeight,
		navigator
	} = context;

	const theme = useTheme();
	const navigate = useNavigate()

	return (
		<>
			<AppBar></AppBar>
			<Grid container sx={{
				pt:appBarHeight+"px",
				minHeight: "100svh",
				position: "relative",
				"& span": {
					color: theme.palette.primary.main
				},
				"&::before": {
          content: "''",
          position: "absolute",
          background: "linear-gradient(0deg, rgba(0,0,0, 1) 0%, rgba(0,0,0, 0) 50%, rgba(0,0,0, 1) 100%)",
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          zIndex: -1,
        },
				}}>
				<Grid xs={12} p={3}>
					<Box sx={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						gap: 3,
						flexDirection: "column",
						[theme.breakpoints.up("sm")]: {
							flexDirection: "row",
						},
						img: {
							maxWidth: "50%",
							maxHeight: "200px",
						}
					}}>

					<img src={"/assets/images/LOGO-HSG-W.png"} alt="High Stone Games white logogram"></img>
					<Box>
						<Typography variant="h1" sx={{
							textAlign: "center",
							mb: "8px",
							fontSize: "5vw !important",
							[theme.breakpoints.down("sm")]:{
								fontSize: "15vw !important",
							},
							[theme.breakpoints.up("sm")]:{
								textAlign: "left",
							}
							}}>About Us</Typography>
						<Typography variant="h3" sx={{
							textAlign: "center",
							color: theme.palette.primary.main,
							[theme.breakpoints.down("sm")]:{
								fontSize: "8vw",
							},
							[theme.breakpoints.up("sm")]:{
								textAlign: "left",
							}
							}}>Meet the team that makes dreams come true</Typography>
					</Box>
					</Box>
				</Grid>
				<Grid  xs={12}>
					<TeamImages></TeamImages>
				</Grid>
				{/* INTRO TEXT */}
				<Grid xs={10} xsOffset={1} >
					<Typography variant="h3" sx={{
						mt: "80px",
					}}>
						We are a team of diverse individuals, united by our passion for <span>videogames</span>.
					</Typography>
					<br></br>
					<Typography variant="h4">
						At <span>High Stone Games</span>, we are devoted to creating high-quality games that are both <span>fun</span> and <span>challenging</span>. We are experts in a variety of genres, including action, run'n'gun, shoot'em up and farming.
					</Typography>
					<br></br>
					<Typography variant="h4">
					<span>High Stone Games</span> is an independent game studio founded in 2015 by a group of <span>passionate gamers</span>. Since then, we have been working tirelessly to create innovative and engaging games that our players will love.
					</Typography>
					<br></br>

					<Typography variant="h4">
						We have learned a lot over the years, and we are <span>constantly evolving</span> our skills and techniques. We are confident in our ability to create <span>high-quality games</span> that will meet the expectations of our players.
					</Typography>
					<br></br>

					<Typography variant="h4">
						We are excited to enter this new phase of our journey as a game studio. We are ready to <span>share our games</span> with the world and make a difference in the <span>brand game</span> market.
					</Typography>
				</Grid>
				<Grid xs={10} xsOffset={1}>
					<Box sx={{
						py: 10,
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
						gap: 2
					}}>
					<Typography variant="h3">Check out our <span>works</span> and our current <span>products!</span></Typography>
					<Button sx={{
						cursor: "pointer",
						textDecoration: "none",
						fontSize: "2.5vw",
						borderRadius: "200px",
						p: 2,
						px: 5,
						mt: "20px"
					}}
					variant="outlined"
					onClick={() =>{navigate(routes.works)}}>Show me</Button>
					</Box>
				</Grid>
			</Grid>
			<Grid container py="80px" sx={{background:"#000"}}>
				<Grid xs={12}>
					<ContactUs/>
				</Grid>
			</Grid>
			<Footer></Footer>
		</>
	)
}
