import { Box, Divider, Drawer, Typography, useTheme } from "@mui/material";
import { useContext } from "react";
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import Context from "../contexts/context";
import { useNavigate, Link } from "react-router-dom";
import { routes } from "../routes/Routes"
export default function DrawerMenu(params) {

	const { openDrawer, toggleDrawer } = params

	const context = useContext(Context);

	const { appBarHeight, drawerWidth } = context

	const theme = useTheme();

	const navigate = useNavigate()

	return (
		<Drawer anchor="right" open={openDrawer} onClick={toggleDrawer}
		sx= {{
			zIndex: 1000,
			"& .MuiPaper-root": {
				backgroundColor: "transparent",
				background:"linear-gradient(128deg, rgba(255,204,0,1) 0%, rgba(255,153,0,1) 50%, rgba(204,102,0,1) 100%)",
				backdropFilter: "blur(8px)",
				pt: appBarHeight+"px"
			}
		}}
		>
			<Box pt={2} px={2}  maxWidth={window.innerWidth+"px"} sx= {{
				width: drawerWidth+"px",
				display: "flex",
				flexDirection: "column",
				boxSizing: "border-box",
				gap: 2,
				"& a": {
					transition:"0.3s all ease-in",
					color: "white",
					textDecoration: "none",
					cursor: "pointer",
					"&:hover": {
						backdropFilter: "blur(8px)",
					}
				},
				[theme.breakpoints.down("sm")]: {
					width: window.innerWidth+"px",
				}
			}}>
				<Link to={routes.home}>
					<Typography color="white">Home</Typography>
				</Link>
				<Link to={routes.games}>
					<Typography color="white">Games</Typography>
				</Link>
				<Link to={routes.team}>
					<Typography color="white">About us</Typography>
				</Link>
				<Link to={routes.works}>
					<Typography color="white">Our works</Typography>
				</Link>
				<Divider color="#ffffff" sx={{ my: "16px"}}></Divider>
				<Link to={routes.games + "aerials/"}>
					<Typography color="white">Aerials</Typography>
				</Link>
				<Link to={routes.games + "galaxy_neon/"}>
					<Typography color="white">Galaxy Neon</Typography>
				</Link>
				<Link to={routes.games + "monster_splitter/"}>
					<Typography color="white">Monster Splitter</Typography>
				</Link>
				<Link to={routes.games + "virulent_addiction/"}>
					<Typography color="white">Virulent Addiction</Typography>
				</Link>
				<Link to={routes.games + "star_bugs/"}>
					<Typography color="white">Star Bugs</Typography>
				</Link>
				<Link to={routes.games + "bongville/"}>
					<Typography color="white">Bongville</Typography>
				</Link>
				<Divider color="#ffffff" sx={{ my: "16px"}}></Divider>
				<Box sx={{
						display: "flex",
						flexWrap: "wrap",
						gap: 1,
						alignItems: "center",
						justifyContent: "center",
						mt: "32px"
					}}>
						<a color="#ffffff" href="https://www.facebook.com/HighStoneGames/" target="_blank" rel="noreferrer">
						<FacebookIcon/>
						</a>
						<a color="#ffffff" href="https://www.instagram.com/highstonegames/" target="_blank" rel="noreferrer">
						<InstagramIcon/>
						</a>
						<a color="#ffffff" href="https://twitter.com/highstonegames" target="_blank" rel="noreferrer">
						<TwitterIcon/>
						</a>
						<a color="#ffffff" href="https://www.linkedin.com/company/high-stone-games/" target="_blank" rel="noreferrer">
						<LinkedInIcon/>
						</a>
					</Box>
			</Box>
		</Drawer>
	)
}
