import { Box, Tooltip, useTheme } from "@mui/material"
import { useState } from "react"
import { GamesInfo } from "../helpers/GameseInfo"
import { useNavigate } from "react-router-dom";

export default function TitleList(params) {

	const theme = useTheme()

	const navigate = useNavigate()

	const handleClick = (game) => {
		navigate("/games/" + game +"/")
	}

	return (
		<Box sx={{
			height: "100%",
			display: "flex",
			flexWrap: "wrap",
			gap: 2,
			alignItems: "center",
			justifyContent: "center",
			alignContent: "center",
			"& .gameTitle": {
				maxWidth: "100%",
				aspectRatio: "1",
				cursor: "pointer",
				transition: "0.3s all ease-in",
				[theme.breakpoints.up("sm")]: {
					maxWidth: "calc(calc(100% - 16px) / 2)",
				},
				[theme.breakpoints.up("md")]: {
					maxWidth: "calc(calc(100% - 32px) / 3)",
				},
				[theme.breakpoints.up("lg")]: {
					maxWidth: "calc(calc(100% - 48px) / 4)",
				},
				"&:hover": {
					transform: "scale(1.05)"
				},
				"img": {
					width: "100%",
					height: "100%",
					aspectRatio: "1",
					objectFit: "contain",
				}
			}
		}}>
			{
				Object.keys(GamesInfo).map((gameId) => {
					return (
						<Tooltip title={GamesInfo[gameId].title} key={"HSG_game_" + gameId+"_tooltip"}>

						<Box className="gameTitle" onClick={() => { handleClick(gameId) }} key={"HSG_game_" + gameId} sx={{

						}}>
							<img src={GamesInfo[gameId].logo} width="1080" height="1080" alt={"game title artwork of " + GamesInfo[gameId].title} />
						</Box>
						</Tooltip>
					)
				})
			}
		</Box>
	)
}
