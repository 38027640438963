import { useMemo, useState, useEffect } from "react"
import { createTheme, ThemeProvider, responsiveFontSizes  } from "@mui/material"
import Context from "./context"
// import "@fontsource/lilita-one"
import "@fontsource/poppins/900.css"


export default function AppContext({ children }) {
  let theme = createTheme({
    palette: {
      mode: 'dark',
      primary: {
        main: "#ff9900",
      },
    },
    typography: {
      fontFamily: "Poppins",
    },
  });

  const [appBarHeight, setAppBarHeight] = useState( "60");
  const drawerWidth = "300";

  theme = useMemo(
    () =>
			responsiveFontSizes(createTheme(theme, {
        typography: {
          h1: {
						fontSize: "5rem",
            [theme.breakpoints.down("lg")]: {
              fontSize: "4rem",
            },
            [theme.breakpoints.down("md")]: {
              fontSize: "3rem",
            },
            [theme.breakpoints.down("sm")]: {
              fontSize: "2rem",
            }
          },
					body1: {
						fontSize: "1.2rem"
					}
        },
        components: {
          MuiButton: {
            styleOverrides: {
              root: {
                borderRadius: "5rem",
              },
            },
          },
          MuiAppBar: {
            styleOverrides: {
              root: {
                padding: "15px 20px",
                height: `${appBarHeight}px`,
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "row",
                ".logo": {
                  height: "100%",
                },
                ".section": {
                  width: "33.3%",
                  height: "100%",
                  display: "flex",
                  alignItems: "center",

                  "&.center": {
                    flexDirection: "column",
                    justifyContent: "center",
                  },
                  ".element": {

                  }
                }
              },
            },
          },
          MuiSwitch: {
            styleOverrides: {
              root: {
                width: 52,
                height: 32,
                padding: 0,
                '& .MuiSwitch-switchBase': {
                  padding: 0,
                  margin: "4px 2px",
                  transitionDuration: '300ms',
                  '&.Mui-checked': {
                    transform: 'translateX(22px)',
                    color: '#fff',

                    '& .MuiSwitch-thumb': {
                      position: "relative",
                      background: "#ffffff",
                      width: 24,
                      height: 24,
                      margin: 0,
                    },
                    '& + .MuiSwitch-track': {
                      backgroundColor: theme.palette.primary.main,
                      opacity: 1,
                      border: 0,
                    },
                    '&.Mui-disabled + .MuiSwitch-track': {
                      opacity: 0.5,
                    },
                  },
                  '&.Mui-focusVisible .MuiSwitch-thumb': {
                    color: '#33cf4d',
                    border: '6px solid #fff',
                  },
                  '&.Mui-disabled .MuiSwitch-thumb': {
                    color: theme.palette.grey[500],
                  },
                  '&.Mui-disabled + .MuiSwitch-track': {
                    opacity: 0.7,
                  },
                },
                '& .MuiSwitch-thumb': {
                  boxSizing: 'border-box',
                  background: "#6B7280",
                  width: 16,
                  height: 16,
                  marginTop: "4px",
                  marginLeft: "4px",
                },
                '& .MuiSwitch-track': {
                  borderRadius: 100,
                  backgroundColor: '#D1D5DB',
                  opacity: 1,
                  transition: theme.transitions.create(['background-color'], {
                    duration: 500,
                  }),
                },
              }
            }
          }
        },
      })),
  )

  const [loading, setLoading] = useState(true)
  const [loadingScene, setLoadingScene] = useState(true)

  const value = useMemo(
    () => ({
      loading, setLoading, loadingScene, setLoadingScene, appBarHeight, drawerWidth
    }),
    [loading, loadingScene]
  )

  return (
    <Context.Provider value={value}>
      <ThemeProvider theme={theme}>
        {children}
      </ThemeProvider>
    </Context.Provider>
  );
}
